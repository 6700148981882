import _ from 'lodash';
import { useGetDistributionGames } from '~components/hooks';
import { Distribution } from './Distribution';
import './distributionsList.scss';

export const Distributions = () => {
  const { data, loading } = useGetDistributionGames({
    filterOrder: {
      sortField: 'type',
      sortOrder: 'ASC',
    },
  });

  if (loading) {
    return null;
  }

  const getDistributionGames = data?.getDistributionGames;

  if (_.size(getDistributionGames) === 0) {
    return null;
  }

  return (
    <div className="giveaways">
      <h2 className="giveaways_title">GIVEAWAYS</h2>
      <div className="giveaways_list">
        {_.map(getDistributionGames, (getDistributionGame) => (
          <Distribution
            key={getDistributionGame.id}
            getDistributionGame={getDistributionGame}
          />
        ))}
      </div>
    </div>
    // <div className="distribution-wrap">
    //   <div className="distribution-title">
    //     <span>Giveaways</span>
    //   </div>
    //   <div className="distribution">
    //     {_.map(getDistributionGames, (getDistributionGame) => (
    //       <Distribution
    //         key={getDistributionGame.id}
    //         getDistributionGame={getDistributionGame}
    //       />
    //     ))}
    //   </div>
    // </div>
  );
};
