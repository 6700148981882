export default function CircleArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        d="M19.984 10A9.963 9.963 0 0 1 10 19.984 9.963 9.963 0 0 1 .016 10 9.963 9.963 0 0 1 10 .016 9.963 9.963 0 0 1 19.984 10ZM10 12.813c0 .421.563.656.844.328l2.812-2.766c.188-.234.188-.516 0-.703l-2.812-2.813c-.281-.328-.844-.093-.844.329v1.828H7a.961.961 0 0 0-.984.984c0 .563.421.984.984.984h3v1.829Z"
      />
    </svg>
  );
}
