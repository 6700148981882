export default function Stats6(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="#FFB800"
        d="M23.86 34.297c0 .469-.157.86-.547 1.172l-1.563 1.718a1.799 1.799 0 0 1-2.422 0l-4.297-4.374c-.703-.626-.703-1.641 0-2.344l2.11-2.11-2.11-2.187a1.566 1.566 0 0 1 0-2.344l2.11-2.187v-.547c-3.828-1.407-6.641-5.078-6.641-9.453 0-6.172 5.703-11.094 12.188-9.766 3.75.86 6.875 3.906 7.578 7.734a9.929 9.929 0 0 1-6.407 11.485v13.203ZM15.5 11.64c0 2.812 2.266 5 5 5s5-2.188 5-5c0-2.735-2.266-5-5-5s-5 2.265-5 5Z"
      />
    </svg>
  );
}
