import GameMode from '../game-mode/GameMode';
import CircleArrowRight from './circle-arrow-right';
import { Button } from '~shared/frontend/ui/button/button';
import './gameModeList.scss';
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useOpenModal } from '~frontend/components/modals/hooks';
import { useGetDistributionGames } from '~frontend/components/hooks';

//TO DO: Maybe memo component mode?
export default function GameModeList({ gameModes, isMobile }) {
  const { data, loading } = useGetDistributionGames({
    filterOrder: {
      sortField: 'type',
      sortOrder: 'ASC',
    },
  });
  const { t } = useTranslation();
  const { onShow } = useOpenModal();

  if (loading) {
    return null;
  }

  const getDistributionGames = data?.getDistributionGames;

  const usersInDistributionsCount = getDistributionGames.reduce((acc, game) => {
    acc += game.usersCount;
    return acc;
  }, 0);

  const handleShowModesClick = () => {
    onShow('PopularModal');
  };
  return (
    <div className="game_mode_list_wrap">
      <div className="game_mode_list_title">
        <h2>{t('popular_modes')}</h2>
        <Button
          text={t('all_modes')}
          icon={<CircleArrowRight />}
          icon_direction="right"
          onClick={handleShowModesClick}
          className="game_mode_list_title_btn"
        />
      </div>
      <div className="game_mode_list">
        {gameModes.map((gameMode, index) => {
          // if (isMobile && index > 1) return;
          return (
            <GameMode
              key={index}
              {...gameMode}
              usersInDistributionsCount={usersInDistributionsCount}
            />
          );
        })}
      </div>
    </div>
  );
}
