export default function ContractsNew(props) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4059_139821)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3337 36.6668C29.5384 36.6668 37.0003 29.2049 37.0003 20.0002C37.0003 10.7954 29.5384 3.3335 20.3337 3.3335C11.1289 3.3335 3.66699 10.7954 3.66699 20.0002C3.66699 29.2049 11.1289 36.6668 20.3337 36.6668ZM18.7184 12.0793C18.902 12.8003 19.5556 13.3335 20.3337 13.3335C21.1118 13.3335 21.7654 12.8003 21.9489 12.0793C25.1168 12.7218 27.612 15.2171 28.2546 18.3849C27.5336 18.5685 27.0003 19.2221 27.0003 20.0002C27.0003 20.7783 27.5336 21.4318 28.2546 21.6154C27.612 24.7832 25.1168 27.2785 21.9489 27.921C21.7654 27.2001 21.1118 26.6668 20.3337 26.6668C19.5556 26.6668 18.902 27.2001 18.7184 27.921C15.5506 27.2785 13.0553 24.7832 12.4128 21.6154C13.1338 21.4318 13.667 20.7783 13.667 20.0002C13.667 19.2221 13.1338 18.5685 12.4128 18.3849C13.0553 15.2171 15.5506 12.7218 18.7184 12.0793ZM22.0003 8.45165V6.66683C22.0003 5.74636 21.2542 5.00017 20.3337 5.00017C19.4132 5.00017 18.667 5.74636 18.667 6.66683V8.45165C13.5575 9.1825 9.51602 13.224 8.78516 18.3335H7.00035C6.07987 18.3335 5.33368 19.0797 5.33368 20.0002C5.33368 20.9206 6.07987 21.6668 7.00035 21.6668H8.78516C9.51601 26.7763 13.5575 30.8178 18.667 31.5487V33.3335C18.667 34.254 19.4132 35.0002 20.3337 35.0002C21.2542 35.0002 22.0003 34.254 22.0003 33.3335V31.5487C27.1098 30.8178 31.1513 26.7763 31.8822 21.6668H33.667C34.5875 21.6668 35.3337 20.9206 35.3337 20.0002C35.3337 19.0797 34.5875 18.3335 33.667 18.3335H31.8822C31.1513 13.224 27.1098 9.1825 22.0003 8.45165ZM20.3337 25.0002C23.0951 25.0002 25.3337 22.7616 25.3337 20.0002C25.3337 17.2387 23.0951 15.0002 20.3337 15.0002C17.5723 15.0002 15.3337 17.2387 15.3337 20.0002C15.3337 22.7616 17.5723 25.0002 20.3337 25.0002ZM20.3337 21.6889C19.401 21.6889 18.645 20.9328 18.645 20.0002C18.645 19.0675 19.401 18.3115 20.3337 18.3115C21.2663 18.3115 22.0224 19.0675 22.0224 20.0002C22.0224 20.9328 21.2663 21.6889 20.3337 21.6889Z"
          fill="#FFB800"
        />
      </g>
      <defs>
        <clipPath id="clip0_4059_139821">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.333496)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
