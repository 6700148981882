import './gameMode.scss';
import Clock from './clock';
import online from '../../../scss/images/online.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '~frontend/components/modals/hooks';

export default function GameMode({
  title,
  text,
  bg = null,
  image = null,
  count = null,
  href = null,
  usersInDistributionsCount = 0,
  onClick,
}) {
  const { t } = useTranslation();
  //невозможность подвязать несколько onShow для разных модалок если надо будет, так как useModal требует название модалки и отдает метод для открытие конкретной
  const { onShow } = useModal('GiveawaysModal');
  //временно задаем прозрачность 48 для отключенных режимов
  const opacity =
    bg === 'bg-game-2' || bg === 'bg-game-3' || bg === 'bg-game-5'
      ? '48%'
      : '100%';
  const cursor =
    bg === 'bg-game-2' || bg === 'bg-game-3' || bg === 'bg-game-5'
      ? 'default'
      : 'pointer';

  //пока модалки раздач
  const handleGiveawaysClick = () => {
    onShow();
  };

  const wheelHref = '/wheel';

  return (
    <a
      href={text === 'bonus_wheel_desc' ? wheelHref : href}
      //className={`${styles.game_mode} ${styles[bg]}`}
      className={`game_mode ${bg}`}
      style={{ opacity: `${opacity}`, cursor: `${cursor}` }}
      //проверка для показа модалки раздча, что мы нажали именно на мод раздач, цепляюсь пока что за свойство text
      onClick={text === 'giveaways_desc' ? handleGiveawaysClick : onClick}
    >
      <div className="game_mode_title">{t(`${title}`)}</div>
      <div className="game_mode_text">{t(`${text}`)}</div>
      <div className="game_mode_count">
        <img src={online} width={16} height={16} />
        {title === 'blog tag distributions' ? usersInDistributionsCount : count}
      </div>
      <LazyLoadImage
        className="game_mode_image"
        src={image}
        alt={title}
        width={256}
        height={159}
      />
    </a>
  );
}
