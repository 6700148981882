export default function Stats1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="#FFB800"
        d="M26.64 18.36c-2.734 0-5-2.266-5-5 0-2.813 2.266-5 5-5 2.813 0 5 2.187 5 5 0 2.734-2.187 5-5 5Zm-13.28 0c-2.813 0-5-2.266-5-5 0-2.813 2.187-5 5-5 2.734 0 4.921 2.187 4.921 5 0 2.734-2.187 5-4.922 5Zm0 3.28c3.828 0 11.64 1.954 11.64 5.86V30c0 .938-.781 1.64-1.64 1.64h-20c-.938 0-1.72-.703-1.72-1.64v-2.5c0-3.906 7.813-5.86 11.72-5.86Zm13.28 0c3.907 0 11.72 1.954 11.72 5.86V30c0 .938-.782 1.64-1.72 1.64h-8.593c.156-.468.312-1.093.312-1.64v-2.5c0-2.422-1.328-4.297-3.28-5.703v-.078c.546 0 1.093-.078 1.562-.078Z"
      />
    </svg>
  );
}
