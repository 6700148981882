export default function UpgradesNew(props) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4059_139831)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6667 3.3335C11.4619 3.3335 4 10.7954 4 20.0002C4 29.2049 11.4619 36.6668 20.6667 36.6668C29.8714 36.6668 37.3333 29.2049 37.3333 20.0002C37.3333 10.7954 29.8714 3.3335 20.6667 3.3335ZM17.3333 21.6668V30.0002H24V21.6668H30.6667L20.6667 10.0002L10.6667 21.6668H17.3333Z"
          fill="#FFB800"
        />
      </g>
      <defs>
        <clipPath id="clip0_4059_139831">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.666504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
