import { FunctionComponent, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { useTimer } from '~components/hooks';

interface Props {
  endDate: Date;
  isOnlyDay?: boolean;
  isOnlyDayAndTime?: boolean;
}

export const ButtonTimer: FunctionComponent<Props> = memo(
  ({ endDate, isOnlyDay = false, isOnlyDayAndTime = false }) => {
    const { t } = useTranslation();
    const eventTimer = useTimer(endDate);

    if (!eventTimer) {
      return null;
    }

    if (isOnlyDay) {
      return (
        <>
          {t('Till the end')}: {eventTimer?.days} {t('day_plural')}
        </>
      );
    }

    if (isOnlyDayAndTime) {
      return (
        <div className="event-timer">
          <span className="icon material-icons" data-icon="schedule"></span>
          <div className="banner_slider_item_timer">
            <span className="day">
              {eventTimer?.days} {t('d')}.
            </span>
            <span className="time">
              {eventTimer?.hours} : {eventTimer?.minutes} :{' '}
              {eventTimer?.seconds}
            </span>
          </div>
        </div>
      );
    }

    return (
      <span className="banner_slider_item_timer">
        {eventTimer?.days} {t('d')}. {eventTimer?.hours} : {eventTimer?.minutes}{' '}
        : <span>{eventTimer?.seconds}</span>
      </span>
    );
  },
);
