'use client';

import { useState } from 'react';
import './textDropdown.scss';

import ArrowBottom from './arrow-bottom';

export default function TextDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="text_dropdown">
      <div className={`text_dropdown_content ${isOpen ? 'active' : ''}`}>
        <div className="text_dropdown_title">About cscase.com</div>
        <div className="text_dropdown_text">
          <h1>CS:GO (CS2) Cases Opening Site</h1>
          <p>
            Do you want to open CSGO cases and get premium skins for your
            weapons? Our site lets players open boxes online in a safe and fair
            way, giving you access to rare, special items. We offer a large
            number of cases to open at reasonable prices, including basic weapon
            crates, special limited edition pieces, and event-based collections
            created for dedicated fans.
          </p>
          <h2>How It Works</h2>
          <p>
            Our platform is one of the best CSGO case opening sites. Getting
            skins on our site is simple and quick. Just follow a few easy steps
            in the guide below:
          </p>
          <ul>
            <li>
              Create an account. You can sign up through Steam or social media
              profiles in just a few clicks. Registration is fast, secure, and
              easy for you. After that, your profile will be set and ready for
              CSGO box opening.
            </li>
            <li>
              Deposit funds. After creating your account, you should add money
              using the payment methods listed on the website. Check the minimum
              deposit amount before proceeding.
            </li>
            <li>
              Open cases. Now you are good to go! Select any CS2 case on our
              website you like and go try your luck opening. Each page clearly
              shows all possible drops along with their drop chances. Click to
              open a case and instantly see what you’ve won.
            </li>
            <li>
              Manage skins. After opening CSGO cases, you decide what comes
              next. You can also withdraw items directly to your Steam inventory
              if you’d like to keep them. Trading and upgrading your weapon
              skins is available anytime.
            </li>
          </ul>
          <h2>What We Offer</h2>
          <p>
            If you want to open a CSGO crate, our site has helpful features and
            services. Here is a list of key benefits you get when using our
            platform:
          </p>
          <ul>
            <li>
              Extensive CSGO case selection. Players have a large selection of
              CS (CS2) cases on our website. Both low-cost and expensive crates
              are available to open. In each crate, you can find skin options,
              skin rarities, and items inside. Users can see skins values before
              opening and find the best CSGO case of their choice.
            </li>
            <li>
              Fair and transparent. When consumers purchase CSGO containers,
              they’ll get results they can trust. This setup guards against
              unfair play and manipulating results. It ensures each CSGO crate
              is both fair and random.
            </li>
            <li>
              Skin upgrade system. Players may trade their skins with lower
              market values to get better items. They pick the skin they want as
              their goal. And then, select several items of less value to
              exchange for it. This lets users build stronger collections from
              boxes opened before. You can choose skins from a variety of sets,
              like “Weapon Case 3,” “Phoenix,” or “Vanguard.”
            </li>
            <li>
              Get your skins fast. Buy a Counter-Strike case, and your new skins
              are delivered to your Steam inventory in no time. Skins become
              available right after you complete the withdrawal request.
            </li>
            <li>
              Exclusive promotions. Our website offers a CSGO case opening
              service with bonuses for new players, promo codes, referrals, and
              more extra options on special event days. You can receive the best
              CS welcome bonuses right after creating an account or entering
              certain codes we give out regularly. This gives you access to
              every skin case CS GO has to offer.
            </li>
          </ul>
          <h2>Why Choose Our Website?</h2>
          <p>
            Our platform offers beneficial and unique features among all the
            other CSGO skin opening sites. We provide easy and secure services
            for every user to open collectible crates safely. Check our benefits
            below:
          </p>
          <ul>
            <li>
              100% secure transactions. Our payment methods have encryption that
              protects your personal information. All deposits and withdrawals
              use strong security technology to prevent fraud or data theft. You
              can make all the money transactions to buy Counter Strike cases
              safely.
            </li>
            <li>
              Daily and weekly bonuses. Users get regular rewards, like a free
              CS GO case through our Wheel Bonus feature, or special promotions.
              First-time players also receive welcome promo codes when creating
              accounts. Active members gain loyalty rewards, as they continue
              using the platform.
            </li>
            <li>
              User-friendly interface. Our website is designed to be simple and
              intuitive for everyone. You can easily navigate through the site
              to find any CS2 cases, view available skins, and bonuses. Even
              first-time players quickly understand how everything works.
            </li>
          </ul>
          <h2>Help & Assistance </h2>
          <p>
            Our dedicated support team is available to assist with any questions
            or issues you may encounter while using our CSGO case opening
            services. Please consider the following support options:
          </p>
          <ul>
            <li>
              Live Chat Support. For immediate assistance, utilize our live chat
              function located at the bottom right corner of each page. Our
              support specialists provide prompt responses to address your
              concerns efficiently.
            </li>
            <li>
              Email Communication. For more complex inquiries, please contact
              our support team via email. Include relevant details such as your
              username, transaction information, time of issue occurrence, and
              supporting screenshots when applicable. Providing comprehensive
              information ensures faster resolution of your inquiry.
            </li>
            <li>
              FAQ. Before contacting support, we recommend reviewing our
              Frequently Asked Questions section. This page contains detailed
              answers to common questions and solutions to typical issues
              encountered when opening CSGO cases.
            </li>
            <li>
              Feedback Submission. We value user input regarding our services.
              If you have suggestions for platform improvements or additional
              features, please submit them through our dedicated feedback
              channel. User recommendations are carefully considered during our
              regular service enhancement processes.
            </li>
          </ul>
          <p>
            All support services are available to ensure an optimal experience
            while using our CSGO case opening platform.
          </p>
        </div>
      </div>
      <div
        className={`text_dropdown_button ${isOpen ? 'active' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ArrowBottom />
        <span>{isOpen ? 'Hide' : 'Show more'}</span>
      </div>
    </div>
  );
}
